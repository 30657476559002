import React from 'react';
import { Switch, Route } from "react-router-dom";

//import { connect } from "react-redux";
//import { getLang } from "../redux/selectors"

import Home from './home';
import Products from './products';
import Services from './services';
import Certifications from './certifications';
import WhoWeAre from './whoWeAre';
import WorkTogether from './workTogether';
import Contact from './contact';

class Pages extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/homelink" component={Home} />
                <Route path="/productslink" component={Products} />
                <Route path="/serviceslink" component={Services} />
                <Route path="/certificationslink" component={Certifications} />
                <Route path="/whowearelink" component={WhoWeAre} />
                <Route path="/worktogetherlink" component={WorkTogether} />
                <Route path="/contactlink" component={Contact} />
            </Switch>
        )
    }
}
/*
const mapStateToProps = state => {
    const currentLang = getLang(state);
    return { currentLang };
};
*/
export default Pages;
//export default connect(mapStateToProps)(Pages);

