import React from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';
import Blueberry from "./detail/blueberry";
import Blackberry from "./detail/blackberry";
import Raspberry from "./detail/raspberry";
import Strawberry from "./detail/strawberry";
import Kiwi from "./detail/kiwi";

class Berries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.berries.description')}
                <Container/>
                <Tabs defaultActiveKey="blueberry" id="uncontrolled-tab-example">
                    <Tab eventKey="blueberry" title={t('products.berries.blueberry.name')}>
                        <Container/>
                        <Blueberry/>
                    </Tab>
                    <Tab eventKey="blackberry" title={t('products.berries.blackberry.name')}>
                        <Container/>
                        <Blackberry/>
                    </Tab>
                    <Tab eventKey="raspberry" title={t('products.berries.raspberry.name')}>
                        <Container/>
                        <Raspberry/>
                    </Tab>
                    <Tab eventKey="strawberry" title={t('products.berries.strawberry.name')}>
                        <Container/>
                        <Strawberry/>
                    </Tab>
                    <Tab eventKey="kiwi" title={t('products.berries.kiwi.name')}>
                        <Container/>
                        <Kiwi/>
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Berries;