import React from 'react';
import { Card, Container, Row, Image} from "react-bootstrap";
import T from 'react-multi-lang';
import "./contact.css"

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container className="main-container-contact">
                <Row className="justify-content-center">
                    <Card style={{ width: '18rem' }}>
                        <Image
                            width={"400px"}
                            src="/contact/relations.svg">
                        </Image>
                        <Card.Body>
                            <Card.Title>{t('contact.relations.title')}</Card.Title>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/personSymbol.svg">
                                </Image>
                                {t('contact.relations.description.name')}
                            </Card.Text>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/phoneSymbol.svg">
                                </Image>
                                {t('contact.relations.description.phone')}
                            </Card.Text>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/mailSymbol.svg">
                                </Image>
                                {t('contact.relations.description.email')}
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{ width: '18rem' }}>
                        <Image
                            width={"255px"}
                            src="/contact/logistic.svg">
                        </Image>
                        <Card.Body>
                            <Card.Title>{t('contact.logistics.title')}</Card.Title>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/personSymbol.svg">
                                </Image>
                                {t('contact.logistics.description.name')}
                            </Card.Text>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/phoneSymbol.svg">
                                </Image>
                                {t('contact.logistics.description.phone')}
                            </Card.Text>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/mailSymbol.svg">
                                </Image>
                                {t('contact.logistics.description.email')}
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{ width: '18rem' }}>
                        <Image
                            width={"235px"}
                            src="/contact/quality.svg">
                        </Image>
                        <Card.Body>
                            <Card.Title>{t('contact.quality.title')}</Card.Title>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/personSymbol.svg">
                                </Image>
                                {t('contact.quality.description.name')}
                            </Card.Text>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/phoneSymbol.svg">
                                </Image>
                                {t('contact.quality.description.phone')}
                            </Card.Text>
                            <Card.Text>
                                <Image
                                    width={"30px"}
                                    src="/contact/mailSymbol.svg">
                                </Image>
                                {t('contact.quality.description.email')}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        )
    };
}

export default Contact;