import React from 'react';
import {Container, Row, Form, Button, Image} from 'react-bootstrap';
import T from 'react-multi-lang';
import "./worktogether.css"

class Enterprises extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                    <Image
                        width={"400"}
                        src="/workTogether/enterprises.svg"/>
                    <Row>
                        {t('workTogether.enterprises.title')}
                    </Row>
                    <Row>
                        {t('workTogether.enterprises.text')}
                    </Row>
                    <Container/>
                    <Row>
                        <Form className="form-group">
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('workTogether.enterprises.form.emailAddress')}</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>{t('workTogether.enterprises.form.exampleSelect')}</Form.Label>
                                <Form.Control as="select">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect2">
                                <Form.Label>{t('workTogether.enterprises.form.exampleMultiSelect')}</Form.Label>
                                <Form.Control as="select" multiple>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>{t('workTogether.enterprises.form.exampleTextArea')}</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                {t('workTogether.enterprises.form.submit')}
                            </Button>
                        </Form>
                    </Row>
            </Container>
        )
    };
}

export default Enterprises;