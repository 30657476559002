import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';

class Citrus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.citrus.description')}
                <Tabs defaultActiveKey="orange" id="uncontrolled-tab-example">
                    <Tab eventKey="orange" title={t('products.citrus.orange.name')}>
                        {t('products.citrus.orange.description')}
                    </Tab>
                    <Tab eventKey="grapefruit" title={t('products.citrus.grapefruit.name')}>
                        {t('products.citrus.grapefruit.description')}
                    </Tab>
                    <Tab eventKey="tangerine" title={t('products.citrus.tangerine.name')}>
                        {t('products.citrus.tangerine.description')}
                    </Tab>
                    <Tab eventKey="lime" title={t('products.citrus.lime.name')}>
                        {t('products.citrus.lime.description')}
                    </Tab>
                    <Tab eventKey="lemon" title={t('products.citrus.lemon.name')}>
                        {t('products.citrus.lemon.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Citrus;