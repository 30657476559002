import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import T from 'react-multi-lang';
import "./whoweare.css"

class Mission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container className="subcontainer">
                <Row className="justify-content-center">
                    <Col sm={5}>
                        <Image
                            width={"300px"}
                            src="/whoWeAre/mission.svg"/>
                    </Col>
                    <Col sm={1}/>
                    <Col sm={5}>
                        <h1>{t('whoWeAre.mission.title')}</h1>
                        <p>
                            {t('whoWeAre.mission.text')}</p>
                    </Col>
                    <Col sm={1}/>
                </Row>
            </Container>
        )
    };
}

export default Mission;