import React from 'react';
import {Container, Row, Image, Form, Button} from 'react-bootstrap';
import T from 'react-multi-lang';
import "./worktogether.css"

class People extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                <Image
                    width={"400"}
                    src="/workTogether/people.svg"/>

                <Row>
                    {t('workTogether.people.title')}
                </Row>
                <Row>
                    {t('workTogether.people.text')}
                </Row>
                <Container/>
                <Row>
                    <Form className="form-group">
                        <Form.Group controlId="formEmail" >
                            <Form.Label>{t('workTogether.people.form.emailAddress')}</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>{t('workTogether.people.form.exampleSelect')}</Form.Label>
                            <Form.Control as="select">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label>{t('workTogether.people.form.exampleMultiSelect')}</Form.Label>
                            <Form.Control as="select" multiple>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{t('workTogether.people.form.exampleTextArea')}</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {t('workTogether.people.form.submit')}
                        </Button>
                    </Form>
                </Row>
            </Container>
        )
    };
}

export default People;