import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang'
import es from './translations/es.json'
import en from './translations/en.json'
import joc from './translations/joc.json'

setDefaultTranslations({es, en, joc})
setDefaultLanguage('es')

ReactDOM.render(
        <App />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
