import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import T from 'react-multi-lang';

function Example() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { t } = T;

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                {t('products.datasheet.modal')}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('products.datasheet.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{t('products.datasheet.name')}</Form.Label>
                            <Form.Control type="text" placeholder="Normal text" />
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>{t('products.datasheet.enterprise')}</Form.Label>
                            <Form.Control type="text" placeholder="Normal text" />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>{t('products.datasheet.email')}</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            <Form.Text className="text-muted">
                                {t('products.datasheet.emailAdvice')}
                            </Form.Text>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            {t('products.datasheet.submit')}
                        </Button>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('products.datasheet.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Example