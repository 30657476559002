import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import T from 'react-multi-lang';
import "./services.css"

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container className="main-container-services">
                {t('page.services.text1')}
                <Row>
                    <Col sm={9}>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/services/first.svg"
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3>{t('services.first.label')}</h3>
                                    <p>{t('services.first.phrase')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/services/second.svg"
                                    alt="Second slide"
                                />
                                <Carousel.Caption>
                                    <h3>{t('services.second.label')}</h3>
                                    <p>{t('services.second.phrase')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/services/third.svg"
                                    alt="Third slide"
                                />
                                <Carousel.Caption>
                                    <h3>{t('services.third.label')}</h3>
                                    <p>{t('services.third.phrase')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/services/fourth.svg"
                                    alt="Fourth slide"
                                />
                                <Carousel.Caption>
                                    <h3>{t('services.fourth.label')}</h3>
                                    <p>{t('services.fourth.phrase')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/services/fifth.svg"
                                    alt="Fourth slide"
                                />
                                <Carousel.Caption>
                                    <h3>{t('services.fifth.label')}</h3>
                                    <p>{t('services.fifth.phrase')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/services/sixth.svg"
                                    alt="Fourth slide"
                                />
                                <Carousel.Caption>
                                    <h3>{t('services.sixth.label')}</h3>
                                    <p>{t('services.sixth.phrase')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/services/seventh.svg"
                                    alt="Fourth slide"
                                />
                                <Carousel.Caption>
                                    <h3>{t('services.seventh.label')}</h3>
                                    <p>{t('services.seventh.phrase')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col sm={3}>
                        {t('services.generalDescription')}
                    </Col>
                </Row>
            </Container>
        )
    };
}

export default Services;