import React from 'react';
import {Container, Row } from 'react-bootstrap';
//import T from 'react-multi-lang';
import "./whoweare.css"
import History from "./history";
import Mission from "./mission";
import Vision from "./vision";

class WhoWeAre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        //const { t } = T;
        return (
            <Container className="main-container-whoWheAre">
                <Row className="justify-content-center">
                    <History/>
                </Row>
                <Row className="justify-content-center">
                    <Mission/>
                </Row>
                <Row className="justify-content-center">
                    <Vision/>
                </Row>
            </Container>
        )
    };
}

export default WhoWeAre;