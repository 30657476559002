import React from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';
import Berries from "./berries";
import Prunaceae from "./ prunaceae";
import Citrus from "./citrus";
import Pomes from "./pomes";
import Grapes from "./grapes";
import Mushrooms from "./mushrooms";
import Cucurbits from "./cucurbits";
import Cabbages from "./cabbages";
import Legumes from "./legumes";
import Spikelets from "./spikelets";
import Asparagales from "./asparagales";
import Datasheet from "./datasheet"

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container className="main-container-products">
                <Tab.Container id="left-tabs-example" defaultActiveKey="berries">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column" >
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="berries">
                                        {t('products.berries.textMenu')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="prunaceae">
                                        {t('products.prunaceae.textMenu')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="citrus">
                                        {t('products.citrus.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="pomes">
                                        {t('products.pomes.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="grapes">
                                        {t('products.grapes.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="mushrooms">
                                        {t('products.mushrooms.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="cucurbits">
                                        {t('products.cucurbits.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="cabbages">
                                        {t('products.cabbages.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="legumes">
                                        {t('products.legumes.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="spikelets">
                                        {t('products.spikelets.textMenu')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-pills active">
                                    <Nav.Link eventKey="asparagales">
                                        {t('products.asparagales.textMenu')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="berries">
                                    <Berries/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="prunaceae">
                                    <Prunaceae/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="citrus">
                                    <Citrus/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="pomes">
                                    <Pomes/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="grapes">
                                    <Grapes/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="mushrooms">
                                    <Mushrooms/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="cucurbits">
                                    <Cucurbits/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="cabbages">
                                    <Cabbages/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="legumes">
                                    <Legumes/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="spikelets">
                                    <Spikelets/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="asparagales">
                                    <Asparagales/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

                <Row>
                    <Col sm={10}/>
                    <Col sm={2}>
                        <Datasheet/>
                    </Col>
                </Row>

            </Container>
        )
    };
}

export default Products;