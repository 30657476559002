import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setLanguage } from 'react-multi-lang'
import './App.css';
import MainNavbar from "./components/navbar";
import Pages from "./pages"
import MainFooter from "./components/footer";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(
            window.localStorage.getItem("lang")) || { lang: "es" };
        setLanguage(this.state.lang);
    };
    componentDidUpdate() {
        window.localStorage.setItem("lang",
        JSON.stringify(this.state)
        );
        console.log(this.state);
        setLanguage(this.state.lang);
    }
    handleChangeLang = (newLang) =>{
        this.setState({
            lang: newLang,
        });
        setLanguage(newLang);
    };
    render() {
        console.log(this.state);
        return (
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Petroval Frozen Foods</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>
                <Router>
                    <MainNavbar ChangeLang = {this.handleChangeLang}/>
                    <Pages />
                </Router>
                <MainFooter />
            </Container>
        );
    }
}

export default App;