import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';

class Spikelets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container>
                {t('products.spikelets.description')}
                <Tabs defaultActiveKey="maize" id="uncontrolled-tab-example">
                    <Tab eventKey="maize" title={t('products.spikelets.maize.name')}>
                        {t('products.spikelets.maize.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Spikelets;