import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';

class Pomes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.pomes.description')}
                <Tabs defaultActiveKey="apple" id="uncontrolled-tab-example">
                    <Tab eventKey="apple" title={t('products.pomes.apple.name')}>
                        {t('products.pomes.apple.description')}
                    </Tab>
                    <Tab eventKey="pear" title={t('products.pomes.pear.name')}>
                        {t('products.pomes.pear.description')}
                    </Tab>
                    <Tab eventKey="quince" title={t('products.pomes.quince.name')}>
                        {t('products.pomes.quince.description')}
                    </Tab>
                    <Tab eventKey="medlar" title={t('products.pomes.medlar.name')}>
                        {t('products.pomes.medlar.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Pomes;