import React from 'react';
import T from 'react-multi-lang';
import {Container, Row} from "react-bootstrap";
import "./home.css"
import Video from "./video";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container className="main-container-contact">
                <Row className="justify-content-center">
                    <h1>
                        {t('page.home.text1')}
                    </h1>
                </Row>
                <Row className="justify-content-center">
                    <Video/>
                </Row>
            </Container>
        )
    };
}

export default Home;