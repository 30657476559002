import React from 'react';
import {Container, Row, Image, Col} from 'react-bootstrap';
import T from 'react-multi-lang';
import './footer.css';

class MainFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "es",
        };
    };
    render() {
        const { t } = T;
        return (
            <Container className="footer">
                <Row>
                    <Col sm={4}>
                        <Row className="justify-content-center">
                            <p >Ruta L49 Kilómetro 3, Sector Miraflores</p>
                        </Row>
                        <Row className="justify-content-center">
                            <p>Longaví - Chile</p>
                        </Row>
                        <Row className="justify-content-center">
                            <a
                                rel="noreferrer"
                                href="https://www.google.cl/maps/place/Petroval/@-35.9197984,-71.6316629,16.28z/data=!4m5!3m4!1s0x966f5ef17213473d:0xf0d2c68dee0628a!8m2!3d-35.9198154!4d-71.6280917?hl=es&authuser=0"
                                target="_blank"
                            >
                                <Image
                                    width={"50"}
                                    src="/footer/map.svg" />
                            </a>
                        </Row>
                    </Col>
                    <Col sm={4} className="border-left">
                        <Row className="justify-content-center">
                            <Image src="/logo-inverso.png" />
                        </Row>
                        <Row className="justify-content-center">
                            {"2021"}
                        </Row>
                    </Col>
                    <Col sm={4} className="border-left">
                        <Row className="justify-content-center">
                            <p>
                                {t('footer.socialMedia')}
                            </p>
                        </Row>
                        <Row className="justify-content-center">
                            <a
                                rel="noreferrer"
                                href="https://wa.me/+56965894005"
                                target="_blank"
                            >
                                <Image
                                    width={"50"}
                                    src="/footer/whatsapp.svg" />
                            </a>
                            <a
                                rel="noreferrer"
                                href="https://www.instagram.com/petroval_frozenfoods/?hl=es-la"
                                target="_blank"
                            >
                                <Image
                                    width={"50"}
                                    src="/footer/instagram.svg" />
                            </a>
                            <a
                                rel="noreferrer"
                                href="https://web.facebook.com/petroval.ltda/"
                                target="_blank"
                            >
                                <Image
                                    width={"50"}
                                    src="/footer/facebook.svg" />
                            </a>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default MainFooter;