import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
//import T from 'react-multi-lang';
import "./worktogether.css"
import People from "./people";
import Enterprises from "./enterprises";

class WorkTogether extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        //const { t } = T;
        return (
            <Container className="main-container-workTogether">
                <Row >
                    <Col sm={5}>
                        <People/>
                    </Col>
                    <Col sm={1}/>
                    <Col sm={5}>
                        <Enterprises/>
                    </Col>
                    <Col sm={1}/>
                </Row>
            </Container>
        )
    };
}

export default WorkTogether;