import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';

class Cucurbits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.cucurbits.description')}
                <Tabs defaultActiveKey="watermelon" id="uncontrolled-tab-example">
                    <Tab eventKey="watermelon" title={t('products.cucurbits.watermelon.name')}>
                        {t('products.cucurbits.watermelon.description')}
                    </Tab>
                    <Tab eventKey="melon" title={t('products.cucurbits.melon.name')}>
                        {t('products.cucurbits.melon.description')}
                    </Tab>
                    <Tab eventKey="pumpkin" title={t('products.cucurbits.pumpkin.name')}>
                        {t('products.cucurbits.pumpkin.description')}
                    </Tab>
                    <Tab eventKey="malabar" title={t('products.cucurbits.malabar.name')}>
                        {t('products.cucurbits.malabar.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Cucurbits;