import React from 'react';
import {Container, Row, Col, Image, Badge} from 'react-bootstrap';
import T from 'react-multi-lang';
class Blueberry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                <Row>
                    <Col>
                        {t('products.berries.blueberry.description')}
                    </Col>
                    <Col>
                        <Image
                            width={"350px"}
                            src="/products/blueberry.svg"/>
                    </Col>
                </Row>
                <Row>
                    <Badge variant="primary">{t('products.months.august')}</Badge>{' '}
                    <Badge variant="secondary">{t('products.months.september')}</Badge>{' '}
                    <Badge variant="danger">{t('products.months.october')}</Badge>{' '}
                    <Badge variant="warning">{t('products.months.november')}</Badge>{' '}
                    <Badge variant="light">{t('products.months.december')}</Badge>{' '}
                    <Badge variant="primary">{t('products.months.january')}</Badge>{' '}
                    <Badge variant="primary">{t('products.months.february')}</Badge>{' '}
                    <Badge variant="primary">{t('products.months.march')}</Badge>{' '}
                    <Badge variant="primary">{t('products.months.april')}</Badge>{' '}
                    <Badge variant="primary">{t('products.months.may')}</Badge>{' '}
                    <Badge variant="primary">{t('products.months.june')}</Badge>{' '}
                    <Badge variant="primary">{t('products.months.july')}</Badge>{' '}
                </Row>
            </Container>
        )
    };
}

export default Blueberry;