import React from 'react';
import { Container, Row, Card, Button } from 'react-bootstrap';
import T from 'react-multi-lang';
import "./certifications.css"

class Certifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container className="main-container-certifications">
                <Row className="justify-content-center">
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="/certifications/Ou_kosher.svg" />
                            <Card.Body>
                                <Card.Title> {t('certifications.kosher.title')}</Card.Title>
                                <Card.Text>
                                    {t('certifications.kosher.description')}
                                </Card.Text>
                                <Button href={"https://oukosher.org/espanol/que-es-el-kosher/"}
                                        target={"_blank"}
                                    variant="primary">{t('certifications.moreInformation')}
                                </Button>
                            </Card.Body>
                        </Card>
                    <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src="/certifications/brc-food-certificated-logo-vector.svg" />
                        <Card.Body>
                            <Card.Title>{t('certifications.brc.title')}</Card.Title>
                            <Card.Text>
                                {t('certifications.brc.description')}
                            </Card.Text>
                            <Button
                                href={"https://www.brcgs.com/about-brcgs/"}
                                target={"_blank"}
                                variant="primary">{t('certifications.moreInformation')}</Button>
                        </Card.Body>
                    </Card>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="/certifications/fda.svg" />
                            <Card.Body>
                                <Card.Title>{t('certifications.fda.title')}</Card.Title>
                                <Card.Text>
                                    {t('certifications.fda.description')}
                                </Card.Text>
                                <Button
                                    href={"https://www.fda.gov/about-fda/fda-en-espanol"}
                                    target={"_blank"}
                                    variant="primary">{t('certifications.moreInformation')}</Button>
                            </Card.Body>
                        </Card>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="/certifications/logo_ascc.svg" />
                            <Card.Body>
                                <Card.Title>{t('certifications.apl.title')}</Card.Title>
                                <Card.Text>
                                    {t('certifications.apl.description')}
                                </Card.Text>
                                <Button
                                    href={"https://www.ascc.cl/pagina/apl"}
                                    target={"_blank"}
                                    variant="primary">{t('certifications.moreInformation')}</Button>
                            </Card.Body>
                        </Card>
                    <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src="/certifications/ecocert-group-vector-logo.svg" />
                        <Card.Body>
                            <Card.Title>{t('certifications.ecocert.title')}</Card.Title>
                            <Card.Text>
                                {t('certifications.ecocert.description')}
                            </Card.Text>
                            <Button
                                href={"https://www.ecocert.com/es-AR/quienes-somos"}
                                target={"_blank"}
                                variant="primary">{t('certifications.moreInformation')}</Button>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        )
    };
}

export default Certifications;