import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';

class Grapes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.grapes.description')}
                <Tabs defaultActiveKey="crimson" id="uncontrolled-tab-example">
                    <Tab eventKey="crimson" title={t('products.grapes.crimson.name')}>
                        {t('products.grapes.crimson.description')}
                    </Tab>
                    <Tab eventKey="adora" title={t('products.grapes.adora.name')}>
                        {t('products.grapes.adora.description')}
                    </Tab>
                    <Tab eventKey="thompson" title={t('products.grapes.thompson.name')}>
                        {t('products.grapes.thompson.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Grapes;