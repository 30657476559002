import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';

class Mushrooms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.mushrooms.description')}
                <Tabs defaultActiveKey="boletus" id="uncontrolled-tab-example">
                    <Tab eventKey="boletus" title={t('products.mushrooms.boletus.name')}>
                        {t('products.mushrooms.boletus.description')}
                    </Tab>
                    <Tab eventKey="lactarius" title={t('products.mushrooms.lactarius.name')}>
                        {t('products.mushrooms.lactarius.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Mushrooms;