import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';

class Legumes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.legumes.description')}
                <Tabs defaultActiveKey="bean" id="uncontrolled-tab-example">
                    <Tab eventKey="bean" title={t('products.legumes.bean.name')}>
                        {t('products.legumes.bean.description')}
                    </Tab>
                    <Tab eventKey="lentil" title={t('products.legumes.lentil.name')}>
                        {t('products.legumes.lentil.description')}
                    </Tab>
                    <Tab eventKey="chickpea" title={t('products.legumes.chickpea.name')}>
                        {t('products.legumes.chickpea.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Legumes;