import React from 'react';
import { Navbar, Nav, NavItem, NavLink, Image, Dropdown} from 'react-bootstrap';
import T from 'react-multi-lang';

class MainNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "es",
        };
    };
    handleChangeLang = (newLang) =>{
        this.props.ChangeLang(newLang);
    };
    render() {
        const { t } = T;
        return (
            <Navbar fixed="top" bg="white" sticky="top" expand="lg" >
                <Navbar.Brand href="/home">
                    <Image src="/logo.png" rounded />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" >
                    <Nav className="ml-auto">
                        <Nav.Link href="/whowearelink">{t('navbar.whoweare')}</Nav.Link>
                        <Nav.Link href="/certificationslink">{t('navbar.certifications')}</Nav.Link>
                        <Nav.Link href="/productslink">{t('navbar.products')}</Nav.Link>
                        <Nav.Link href="/serviceslink">{t('navbar.services')}</Nav.Link>
                        <Nav.Link href="/worktogetherlink">{t('navbar.worktogether')}</Nav.Link>
                        <Nav.Link href="/contactlink">{t('navbar.contact')}</Nav.Link>
                        <Dropdown as={NavItem} drop="left" onSelect={this.handleChangeLang}>
                            <Dropdown.Toggle as={NavLink} variant="success" id="dropdown-basic" >
                                {t('navbar.language')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu >
                                <Dropdown.Item eventKey={"es"}>Español</Dropdown.Item>
                                <Dropdown.Item eventKey={"en"}>Inglés</Dropdown.Item>
                                <Dropdown.Item eventKey={"joc"}>Jocabed</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
/*
export default connect(
    null,
    { setLang }
)(MainNavbar);
 */
export default MainNavbar;