import React from 'react';
import {Container, Tab, Tabs} from 'react-bootstrap';
import T from 'react-multi-lang';
import './products.css';
import Cherry from "./detail/cherry";
import Plum from "./detail/plum";
import Peach from "./detail/peach";

class Prunaceae extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    };
    render() {
        const { t } = T;
        return (
            <Container >
                {t('products.prunaceae.description')}
                <Container/>
                <Tabs defaultActiveKey="cherry" id="uncontrolled-tab-example">
                    <Tab eventKey="cherry" title={t('products.prunaceae.cherry.name')}>
                        <Container/>
                        <Cherry/>
                    </Tab>
                    <Tab eventKey="plum" title={t('products.prunaceae.plum.name')}>
                        <Container/>
                        <Plum/>
                    </Tab>
                    <Tab eventKey="peach" title={t('products.prunaceae.peach.name')}>
                        <Container/>
                        <Peach/>
                    </Tab>
                    <Tab eventKey="apricot" title={t('products.prunaceae.apricot.name')}>
                        {t('products.prunaceae.apricot.description')}
                    </Tab>
                </Tabs>
            </Container>
        )
    };
}

export default Prunaceae;